<template>
  <v-container class="table-filters" fluid>
    <v-row>
      <v-col cols="12" sm="3">
        <calendar-range-input
          v-model="selectedFiltersCreatedDateRange"
          only-past-dates
          :label="$trans('created_date_range')"
          clearable
          dense
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="12" md="3">
        <groups-field
          v-model="selectedFiltersGroupIds"
          dense
          small-chips
          deletable-chips
          :disabled="disabled"
          multiple
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          v-model="selectedFiltersStatus"
          :items="availableAccountStatusFilters"
          :label="$trans('status')"
          multiple
          small-chips
          deletable-chips
          outlined
          clearable
          hide-details
          :disabled="disabled"
          dense
          :menu-props="{ offsetY: true }"
        >
          <template #item="data">
            <div class="d-flex align-center pointer my-2">
              <span class="text-break cd-v-select-line-height">{{
                data.item.text
              }}</span>
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          v-model="selectedFiltersIntegrations"
          :items="availableIntegrationsFilters"
          :label="$trans('integrations')"
          multiple
          small-chips
          deletable-chips
          outlined
          clearable
          hide-details
          :disabled="disabled"
          dense
          :menu-props="{ offsetY: true }"
        >
          <template #item="data">
            <div class="d-flex align-center pointer my-2">
              <span class="text-break cd-v-select-line-height">{{
                data.item.text
              }}</span>
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="12">
        <v-text-field
          v-model="selectedFiltersSearch"
          :label="$trans('search')"
          :placeholder="$trans('autocomplete_hint')"
          append-icon="$magnify"
          hide-details
          outlined
          single-line
          clearable
          :disabled="disabled"
          dense
        />
      </v-col>
    </v-row>
    <v-row class="my-4" justify="center">
      <v-col class="d-flex align-center justify-center" cols="12" md="3" sm="6">
        <v-btn
          color="primary"
          outlined
          small
          :disabled="disabled"
          @click="clearFilters"
        >
          {{ $trans("clear_filters") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sharedListFilters from "@/calendesk/mixins/sharedListFilters";
import GroupsField from "@/components/Forms/GroupsField.vue";
import CalendarRangeInput from "@/lib/calendesk-js-library/components/CalendarRangeInput.vue";

export default {
  name: "UserFilter",
  components: { CalendarRangeInput, GroupsField },
  mixins: [sharedListFilters],
  data() {
    return {
      cacheId: "user-list-filters",
      selectedFiltersStatus: [],
      selectedFiltersIntegrations: [],
      selectedFiltersSearch: null,
      selectedFiltersIsPublic: null,
      selectedFiltersCreatedDateRange: null,
      selectedFiltersGroupIds: [],
      availableIntegrationsFilters: [
        {
          text: this.$trans("mobile_app_title"),
          value: "app",
        },
      ],
    };
  },
  computed: {
    filters() {
      return {
        selectedFiltersStatus: this.selectedFiltersStatus,
        selectedFiltersSearch: this.selectedFiltersSearch,
        selectedFiltersIntegrations: this.selectedFiltersIntegrations,
        selectedFiltersIsPublic: this.selectedFiltersIsPublic,
        selectedFiltersCreatedDateRange: this.selectedFiltersCreatedDateRange,
        selectedFiltersGroupIds: this.selectedFiltersGroupIds,
      };
    },
  },
  methods: {
    getData() {
      const data = {};

      if (this.selectedFiltersStatus && this.selectedFiltersStatus.length > 0) {
        data.status_ids = this.selectedFiltersStatus.join(",");
      }

      if (
        this.selectedFiltersGroupIds &&
        this.selectedFiltersGroupIds.length > 0
      ) {
        data.group_ids = this.selectedFiltersGroupIds.join(",");
      }

      if (
        this.selectedFiltersCreatedDateRange &&
        this.selectedFiltersCreatedDateRange.length > 0
      ) {
        data.created_from = this.selectedFiltersCreatedDateRange[0];

        if (this.selectedFiltersCreatedDateRange.length === 2) {
          data.created_to = this.selectedFiltersCreatedDateRange[1];
        } else {
          data.created_to = data.created_from;
        }
      }

      if (this.selectedFiltersSearch) {
        data.query = this.selectedFiltersSearch;
      }

      if (
        this.selectedFiltersIntegrations &&
        this.selectedFiltersIntegrations.length > 0
      ) {
        data.integration_ids = this.selectedFiltersIntegrations.join(",");
      }

      return data;
    },
  },
};
</script>
