var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{key:_vm.item.id,staticClass:"table-row",class:{
    'v-data-table__mobile-table-row': !_vm.$vuetify.breakpoint.mdAndUp,
  },on:{"mouseleave":function($event){_vm.isHovered = false},"mouseover":function($event){_vm.isHovered = true},"click":function($event){$event.stopPropagation();return _vm.clickRow(_vm.item)}}},[_c('td',{staticClass:"pt-2 text-center",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__checkbox-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"}):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('v-simple-checkbox',{attrs:{"ripple":false,"value":_vm.isSelected,"color":"primary"},on:{"input":function($event){return _vm.selectOnChange($event)}}})],1)]),_c('td',{staticClass:"pt-2 text-center",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__5-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_c('div',[_vm._v(" "+_vm._s(_vm.$trans("id"))+" ")])]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{staticClass:"text-left"},[_c('div',[_vm._v(_vm._s(_vm.item.id))])])])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__25-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("client_names"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{staticClass:"employee-details"},[_c('div',{staticClass:"mb-2"},[_c('user-card-details',{attrs:{"user":_vm.item,"clickable":"","details-in-tooltip":"","justify-left":"","push-transition":""}})],1),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-chip',{staticClass:"mr-2",class:_vm.$helpers.getStatusColor(_vm.item.status).class,attrs:{"color":_vm.$helpers.getStatusColor(_vm.item.status).color,"x-small":""}},[_vm._v(" "+_vm._s(_vm.$trans(_vm.item.status + "_account"))+" ")])],1),(_vm.item.groups && _vm.item.groups.length > 0)?_c('div',{staticClass:"d-flex align-center flex-wrap mt-2"},_vm._l((_vm.item.groups),function(group){return _c('v-chip',{key:group.id,staticClass:"my-1 mr-2",attrs:{"x-small":""}},[_c('div',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(group.name))])])])}),1):_vm._e()])])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__15-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("email"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[(_vm.item.email)?_c('div',{staticClass:"pointer",on:{"click":function($event){$event.stopPropagation();return _vm.$helpers.copyAndNotify(_vm.item.email)}}},[_vm._v(" "+_vm._s(_vm.item.email)+" ")]):_vm._e()])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__15-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("phone"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[(_vm.item.default_phone)?_c('div',{staticClass:"pointer",on:{"click":function($event){$event.stopPropagation();return _vm.$helpers.copyAndNotify(_vm.item.default_phone.e164)}}},[_vm._v(" "+_vm._s(_vm.item.default_phone.e164)+" ")]):_vm._e()])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__15-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("created_at"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_vm._v(" "+_vm._s(_vm._f("dateTime")(_vm.item.created_at,_vm.$helpers.hourDayMonthAndYearDate))+" ")])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__30-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("integrations"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{staticClass:"d-flex align-center justify-center flex-wrap"},[(_vm.item.accepts_push)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"green"}},on),[_vm._v(" $smartphone")])]}}],null,false,2179381359)},[_vm._v(" "+_vm._s(_vm.$trans("push_tooltip"))+" ")]):_vm._e()],1)])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__manage-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[_c('div',{staticClass:"row-list-buttons white rounded mr-1 mt-2"},[_c('user-list-buttons',{attrs:{"is-hovered":_vm.isHovered,"user":_vm.item}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }