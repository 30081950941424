<template>
  <list-buttons
    :is-hovered="isHovered"
    :extra-actions="extraActions"
    :primary-actions="primaryActions"
    @fireAction="fire"
  />
</template>
<script>
import ListButtons from "@/components/ListButtons.vue";
import userActions from "@/calendesk/mixins/userActions";

export default {
  name: "UserListButtons",
  components: { ListButtons },
  mixins: [userActions],
  props: {
    user: {
      type: Object,
      required: true,
    },
    isHovered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    extraActions() {
      const actions = [];

      actions.push({
        action: "edit",
        color: "light",
        icon: "$pencil",
        title: this.$trans("edit"),
      });

      if (this.canSendNotificationsToUser(this.user)) {
        actions.push({
          action: "send_notification_to_user",
          color: "light",
          icon: "$send",
          title: this.$trans("send_customer_notification_title"),
        });
      }

      actions.push({
        action: "reset_password",
        color: "light",
        icon: "$password",
        title: this.$trans("reset_password"),
      });

      if (this.user.status === "active") {
        actions.push({
          action: "lock",
          color: "error",
          icon: "$lock",
          title: this.$trans("lock_account"),
        });
      } else {
        actions.push({
          action: "unlock",
          color: "light",
          icon: "$unlock",
          title: this.$trans("unblock_account"),
        });
      }

      if (this.loggedUserCanDeleteUsers) {
        actions.push({
          action: "delete",
          color: "error",
          icon: "$trash-default",
          title: this.$trans("delete"),
        });
      }

      return actions;
    },
    primaryActions() {
      const actions = [
        {
          action: "edit",
          color: "light",
          icon: "$pencil",
          title: this.$trans("edit"),
        },
      ];

      if (this.canSendNotificationsToUser(this.user)) {
        actions.push({
          action: "send_notification_to_user",
          color: "light",
          icon: "$send",
          title: this.$trans("send_customer_notification_title"),
        });
      }

      return actions;
    },
  },
  methods: {
    fire(action) {
      switch (action) {
        case "edit":
          this.$router.push({ name: "user", params: { id: this.user.id } });
          break;
        case "send_notification_to_user":
          this.sendCustomerNotification([this.$helpers.cloneObject(this.user)]);
          break;
        case "delete":
          this.handleRemove([this.user], "user");
          break;
        case "lock":
          this.handleLock([this.user]);
          break;
        case "unlock":
          this.handleUnlock([this.user]);
          break;
        case "reset_password":
          this.handleResetPassword(this.user, false);
          break;
      }
    },
  },
};
</script>
